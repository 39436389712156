.slotLabel {
  font-size: 1em;
  font-weight: bolder;
  /* padding: auto; */
}

.slotValue {
  font-size: 1em;
  text-align: center;
  /* font-weight: bolder; */
}

.other {
  /* background-color: lightgray; */
}

.slotWrapper {
  /* border-top: 1px solid black;
  border-bottom: 1px solid black; */

  /* background-color: lightgray; */
  margin: 0!important;
  padding: 0!important;
  /* user-select: none; */
}