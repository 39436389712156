.redLabel,
.greenLabel {
    height: 15px;
    width: 15px;
    /* fill: rgb(219, 101, 101); */
}

.greenLabel {
    /* fill: lightgreen; */
}

.label {
    font-size: 15px;
    /* line-height: 1; */
}

.title {
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
}