.mainHeaderBar {
  background-color: rgba(232, 229, 229, 0.119);
  /* -webkit-box-shadow: 0px -3px 0px 0px rgba(0,0,0,0.42);  */
  /* box-shadow: 0px -3px 0px 0px rgba(0,0,0,0.42); */
  border: 1px solid rgba(190, 188, 188, 0.312);
  border-top: 0;
}

.titleText {
  font-size: 20px;
  font-weight: 500;
}

.component1 {
  border-left: 1px solid rgba(190, 188, 188, 0.312);
}

.component2 {
  border-right: 1px solid rgba(190, 188, 188, 0.312);
}

.backComponent {
  border-right: 1px solid rgba(190, 188, 188, 0.312);
}