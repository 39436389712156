.addAnother {
  border: 1px dashed black;
  cursor: pointer;
  user-select: none;
}

.item {
  border-top: 1px dashed black;
  vertical-align: middle;
  align-items: center;
}

.title {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
 
}